import React from 'react'
import { NavContextProvider } from './nav.context';

const AppProvider = ({ children }) => {
    return (
        <NavContextProvider>
            {children}
        </NavContextProvider>
    )
}
export { AppProvider }