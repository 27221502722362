import React, { createContext, useContext, useRef } from "react";

const NavContext = createContext()

const NavContextProvider = ({ children }) => {
    const aboutRef = useRef(null)
    const clientsRef = useRef(null)
    const servicesRef = useRef(null)
    const contactRef = useRef(null)
    
    return (
        <NavContext.Provider
            value={{
                aboutRef,
                clientsRef,
                servicesRef,
                contactRef
            }}>
            {children}
        </NavContext.Provider>
    )
}

export default NavContext;

const useNav = () => useContext(NavContext)

export { NavContextProvider, useNav }