require("bootstrap/dist/css/bootstrap.css");
require("@fortawesome/fontawesome-free/css/fontawesome.css");
require("@fortawesome/fontawesome-free/css/brands.css");
require("@fortawesome/fontawesome-free/css/solid.css");
//require("wowjs/dist/wow.min.js"); 
const WOW = require("wow.js");
new WOW().init();
require("wow.js/css/libs/animate.css");
require("./src/styles/global.css");
//require("./i18n"); 
const React = require("react")
const { AppProvider } = require("./src/contexts")

exports.wrapRootElement = ({ element }) => {
  return (
    <AppProvider>{element}</AppProvider>
  )

// Logs when the client route changes
/* exports.onRouteUpdate = ({ location, prevLocation }) => {
  console.log("new pathname", location.pathname)
  console.log("old pathname", prevLocation ? prevLocation.pathname : null)
} */


// Wraps every page in a component
/* exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
} */
}